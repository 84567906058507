import { GetStaticPaths, GetStaticProps } from 'next'

import Family from '@/screens/Family'
import { GetFamilyPageDocument } from '@/queries/queries/Family/getFamiliyPage.generated'
import { defaultGetStaticPaths, defaultGetStaticProps } from '@/utils/pageUtils'

export const getStaticPaths: GetStaticPaths = defaultGetStaticPaths

export const getStaticProps: GetStaticProps = async (context) =>
  defaultGetStaticProps('equipment', context, { query: GetFamilyPageDocument }, (data) => data.familyPage == null)
export default Family
